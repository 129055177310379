/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'
import '../../styles/CompanyPerformance.scss'
import {companyPerfDropdowns, getRankingType, getRankingSet, getDirectionColor} from '../../utils/utilities'
import format from '../../utils/format'
import { getPlatformRequest } from '../../api'
import CustomSelect from '../../utils/CustomSelect'
import CompanyPopover from '..//CompanyPopover'

const CompanyPerformance = ({ token, width = '208px', isrealtime = false }) => {
    const companyPerf = new BEMHelper({
        name: 'company-performance'
        })
    const [selectMenus, setSelectMenus] = useState(companyPerfDropdowns)
    const [tableContent, setTableContent] = useState([])
    const [dateAsOf, setDateAsOf] = useState('No Data')
    const [xrefData, setXrefData] = useState(null)
    const getQueryParams = () => {
      // get selected item from ranked-by dropdown
      let selectedMarket = selectMenus?.filter(menu => menu.label === 'markets')[0] || []
      selectedMarket = selectedMarket.items[selectedMarket.selectedItemIndex]?.value

      // get selected item from ranked-by dropdown
      let selectedType = selectMenus?.filter(menu => menu.label === 'ranked-by')[0] || []
      selectedType = selectedType.items[selectedType.selectedItemIndex]?.value

      // get selected item from bucket-size dropdown
      let selectedSet = selectMenus?.filter(menu => menu.label === 'size-buckets')[0] || []
      selectedSet = selectedSet?.items[selectedSet.selectedItemIndex]?.value

      let isRealTime = isrealtime
      // handling null case
      if (!isrealtime) isRealTime = false
      return `rankingSets=${getRankingSet(selectedSet, selectedMarket)}&rankingType=${getRankingType(selectedType)}&count=10&realtime=${isRealTime}`
    }
    const getXids = (rankingList) => {
      return rankingList.map(item => item.venueXid)
    }

    const mapTableData = (rankingList, xrefData) => {
      return rankingList.map(item => {
        const symbol = xrefData?.filter(xid => xid.xids.venue === item.venueXid)[0]?.symbol || null
        return {
          symbol: symbol,
          last: item?.lastTrade?.last,
          change: item?.lastTrade?.change,
          changePct: item?.changePercent?.today
        }
      })
    }

    const getTableContent = async () => {
      // get rankings data
      let RankingsApiresponse = await getPlatformRequest('market-rankings', `detail/multi?${getQueryParams()}`)
      RankingsApiresponse = RankingsApiresponse.data?.data?.marketRankings || []

      if (RankingsApiresponse.count === 0) return

      // get xref data for venueXids
      let xrefResponse = await getPlatformRequest('xref', `venue-xids?venueXids=${getXids(RankingsApiresponse)}`)
      xrefResponse = xrefResponse?.data?.data?.items
      setXrefData(xrefResponse)
      let tableValues = mapTableData(RankingsApiresponse, xrefResponse)
      // picking asof date from rankings response
      let asOfDateVal = RankingsApiresponse.filter(v => v.lastTrade?.date).map(d => d.lastTrade?.date)
      asOfDateVal = (asOfDateVal.length > 0) ? format.formatDateFromDateTime(asOfDateVal[0]) : 'No Data'

      setTableContent(tableValues)
      setDateAsOf(asOfDateVal)
    }

    const handleMenuChange = (menuIndex, selectedValue) => {
        const updatedState = [...selectMenus]
        const newSelectedIndex = updatedState[menuIndex].items.map(item => item.value).indexOf(selectedValue) || 0
        updatedState[menuIndex].selectedItemIndex = newSelectedIndex
        setSelectMenus(updatedState)
    }

    useEffect(() => {
      getTableContent()
    }, [selectMenus])

    return (
      <div {...companyPerf()} style={{width: width}}>
        <header {...companyPerf('wrapper')}>
          <h2>Company Performance</h2>
        </header>
        <section >
          {
          selectMenus.map((menu, index) => {
            return (<CustomSelect
              {...companyPerf(menu.label)}
              label={menu.label}
              items={menu.items}
              selectedItemIndex={menu.selectedItemIndex}
              onChange={(value) => { handleMenuChange(index, value) }}
              key={'custom-select-' + menu.label}
                />)
            })}

          <div className='result-table'>
            <table>
              <thead>
                <tr>
                  <th> </th>
                  <th className='align-right'>Last($)</th>
                  <th className='align-right'>Todays Change</th>
                </tr>
              </thead>
              <tbody>
                {tableContent.map((item, index) => {
                  return (
                    <tr key={'result-table-' + item.symbol}>
                      <td className='result-table-data'>
                        <span className={'arrow icon ' + getDirectionColor(item.change)} />
                        <CompanyPopover symbol={item.symbol} xrefData={xrefData[index]} />
                      </td>
                      <td className='align-right'>{format.number(item.last, 3)}</td>
                      <td className={'align-right change-column ' + getDirectionColor(item.change)}>{format.number(item.change, 3)}({format.number(item.changePct, 2)}%)</td>
                    </tr>
                  )
                })
                }
              </tbody>
            </table>
            <div className='table-footer'>Data as of {dateAsOf}</div>
          </div>
        </section>
      </div>
    )
}

CompanyPerformance.propTypes = {
  token: PropTypes.string,
  width: PropTypes.string,
  isrealtime: PropTypes.bool
}

export default CompanyPerformance
