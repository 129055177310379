/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState} from 'react'
import PropTypes from 'prop-types'

const CustomSelect = ({label, items, selectedItemIndex, onChange}) => {
    CustomSelect.propTypes = {
        label: PropTypes.string,
        items: PropTypes.array,
        selectedItemIndex: PropTypes.number,
        onChange: PropTypes.func
      }

    const [isOptionsOpen, setIsOptionsOpen] = useState(false)

  const setSelectedThenCloseDropdown = (value) => {
    setIsOptionsOpen(false)
    onChange(value)
  }

  return (
    <div className={'custom-select-container ' + label}>
      <select>
        <option key={'option-' + label} value={items[selectedItemIndex].value}>{items[selectedItemIndex].value}</option>
      </select>
      <div className='select-overlay' onClick={() => setIsOptionsOpen(true)} />
      <ul
        className={`options ${isOptionsOpen ? 'show' : ''}`}
        tabIndex={-1}
        onMouseLeave={() => setIsOptionsOpen(false)}
    >
        {items.map((item, index) => (
          <li
            key={'dropdownlist-' + item.value}
            onClick={() => {
            setSelectedThenCloseDropdown(item.value)
            }}
            className={index === selectedItemIndex ? 'selected' : ''}
        >
            {item.value}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CustomSelect
